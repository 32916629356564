import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "distance-ten-usage"
    }}>{`Distance-ten usage`}</h1>
    <p>{`This service handles distances between railway stations, measured along the track.`}</p>
    <p>{`It has an open API for looking up distances, and an internal API for data maintenance.`}</p>
    <h2 {...{
      "id": "background"
    }}>{`Background`}</h2>
    <p>{`Changes in distance data are quite rare. They may happen when the tracks are modified, when a station is taken out of
service or if a new station is introduced.`}</p>
    <p>{`The API for doing data maintenance is still incomplete. The procedure is currently based on running SQL commands in the
distance-ten database, and requires some familiarity with the database structure. Details can be found in this file
in the source code repo:`}</p>
    <pre><code parentName="pre" {...{}}>{`.../distance-ten/src/master/README.md
`}</code></pre>
    <h2 {...{
      "id": "authorization"
    }}>{`Authorization`}</h2>
    <p>{`The maintenance endpoints require authorization with a bearer token based on the distance-ten clientId and -secret.`}</p>
    <p>{`Use the kubernetes secret `}<inlineCode parentName="p">{`distance-ten-client-credentials`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "endpoint-examples"
    }}>{`Endpoint examples`}</h2>
    <p>{`Look up the distance between a pair of stopPlaces:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\
    -X GET \\
    -H "ET-Client-Name: somecompany - someapp" \\
    https://api.entur.io/distance-ten/NSR:StopPlace:337/NSR:StopPlace:1
`}</code></pre>
    <p>{`Verify that the current data set is ok:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\
    -X GET \\
    -H "ET-Client-Name: somecompany - someapp" \\
    https://api.entur.io/distance-ten/admin/verify/CURRENT/1
`}</code></pre>
    <p>{`Verify that data set 3 is ok when compared to data set 2:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\
    -X GET \\
    -H "ET-Client-Name: somecompany - someapp" \\
    https://api.entur.io/distance-ten/admin/verify/3/2
`}</code></pre>
    <p>{`Refresh the cache:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\
    -X POST \\
    -H "Authorization: Bearer $token" \\
    -H "Content-Length: 0" \\
    https://api.entur.io/distance-ten/admin/flush
`}</code></pre>
    <p>{`Swagger api docs:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl https://api.entur.io/distance-ten/api-docs/swagger.json
curl https://api.entur.io/distance-ten/api-docs/swagger.yaml
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      